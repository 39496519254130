import { isNotNil } from '~/utilities/type-guards';
import * as Sentry from '@sentry/react';
import { environment } from '~/constantDefinitions';
import { WistiaError, isWistiaError } from '~/datadogRum/WistiaError';
import { Product, Pillar } from '../../types/wistia';

const environmentsToLog = new Set<string>(['production', 'staging', 'stagingBranchDeploy']);

interface ErrorReportingContext {
  [key: string]: unknown; // from Levi: we should consider making these primitive values
  pillar: Pillar;
  product: Product;
  attachment?: Attachment;
  json?: unknown;
}

interface Attachment {
  data: Uint8Array | string;
  filename: string;
  contentType?: string;
}

/**
 * Reports an error to Sentry.
 *
 * In dev and test environments where Sentry is not available, the error is logged to the console.
 *
 * @param error - The error to report
 * @param context - An optional object containing extra context about the error
 */
export const reportError = (error: Error | WistiaError, context?: ErrorReportingContext): void => {
  if (environmentsToLog.has(environment)) {
    Sentry.captureException(error, (scope) => {
      if (isNotNil(context)) {
        const { attachment, json, ...restContext } = context;
        if (isNotNil(attachment)) {
          scope.addAttachment(attachment);
        }
        if (isNotNil(json)) {
          try {
            scope.setExtra('json', JSON.stringify(json));
            // eslint-disable-next-line no-empty
          } catch {}
        }
        scope.setTags({
          ...restContext,
          pillar: context.pillar,
          product: context.product,
        });
      } else if (isWistiaError(error)) {
        scope.setTags({
          pillar: error.pillar,
          product: error.product,
        });
      } else {
        // Do nothing
      }
      return scope;
    });
  } else {
    // for dev/test environments that do not have Sentry
    console.error(error, { ...context }); // eslint-disable-line no-console
  }
};
