import { gql, useQuery } from '@apollo/client';
import { isBoolean, isNotNil } from '~/utilities/type-guards';
import flagsmith from 'flagsmith';
import { IFlagsmithTrait } from 'flagsmith/types';
import { FlagsmithContextType, FlagsmithProvider, useFlagsmith } from 'flagsmith/react';
import { useEffect, createContext, useState, useContext, JSX } from 'react';
import {
  flagsmithEnvironmentId,
  flagsmithAnonymousEnvironmentId,
  flagsmithV3EnvironmentId,
  flagCacheTTL,
} from '../../constantDefinitions';
import { FlagsmithAnonymousIdentityAndTraitsLoader } from './FlagsmithAnonymousIdentityAndTraitsLoader';
import { FlagsmithV3IdentityAndTraitsLoader } from './FlagsmithV3IdentityAndTraitsLoader';
import { useFlagsmithInitialLoadQueryQuery } from './__gql/FeatureFlag.gql.gen';

const FlagsmithAnonymousIdentityLoadingContext = createContext(true);

export const useFlagsmithAnonymousIdentityLoading = (): boolean => {
  return useContext(FlagsmithAnonymousIdentityLoadingContext);
};

// eslint-disable-next-line rulesdir/no-gql-tags -- TODO: migrate to graphql-codegen
const identityAndTraitsQuery = gql`
  query IdentityAndTraitsQuery {
    currentActor {
      id

      flagsmithId
      flagsmithIdentityData
    }
  }
`;
type CurrentActor = {
  flagsmithId: string;
  flagsmithIdentityData: Record<string, IFlagsmithTrait>;
};

type QueryData = {
  currentActor: CurrentActor;
};

type QueryResult = {
  loading: boolean;
  data: QueryData | undefined;
};

const FlagsmithIdentityLoadingContext = createContext(true);

export const useFlagsmithIdentityLoading = (): boolean => {
  return useContext(FlagsmithIdentityLoadingContext);
};

const FlagsmithIdentityAndTraitsLoader = ({
  setLoading,
  skip,
}: {
  setLoading: (loading: boolean) => void;
  skip?: boolean | undefined;
}) => {
  // eslint-disable-next-line rulesdir/no-use-query -- TODO: migrate to graphql-codegen
  const { data }: QueryResult | undefined = useQuery(identityAndTraitsQuery, {
    skip,
  });
  const { flagsmithId, flagsmithIdentityData } = data?.currentActor ?? {};
  const flagsmithInstance = useFlagsmith();

  useEffect(() => {
    if (flagsmithId != null) {
      const updateFlagsmithIdentity = async () => {
        await flagsmithInstance.identify(flagsmithId, flagsmithIdentityData);
        setLoading(false);
      };

      void updateFlagsmithIdentity();
    }
  }, [flagsmithId, flagsmithIdentityData, flagsmithInstance, setLoading]);

  return null;
};

export const FeatureFlagProvider = ({
  children,
  skipIdentityLoading = false,
  isAnonymous = false,
  onFeatureFlagProviderLoaded,
}: {
  children: FlagsmithContextType['children'];
  skipIdentityLoading?: boolean;
  isAnonymous?: boolean;
  onFeatureFlagProviderLoaded?: () => void;
}): JSX.Element | null => {
  const [loading, setLoading] = useState(true);
  const { data, loading: queryLoading } = useFlagsmithInitialLoadQueryQuery({
    skip: skipIdentityLoading,
  });

  if (isBoolean(queryLoading) && queryLoading) {
    return null;
  }

  // Necessary for testing setup.
  // Can be removed once we have removed customize3 and are fully on customize4
  if (skipIdentityLoading && isNotNil(onFeatureFlagProviderLoaded)) {
    onFeatureFlagProviderLoaded();
  }

  const setLoadingValue = (value: boolean) => {
    setLoading(value);
    // if value is false, we are not loading and should call the callback.
    // This is used by customize3 to know when to begin loading jamjars
    // Note: We may be able to remove onFeatureFlagProviderLoaded
    // once we are fully on customize4
    if (isBoolean(value) && !value && isNotNil(onFeatureFlagProviderLoaded)) {
      onFeatureFlagProviderLoaded();
    }
  };

  // Flagsmith V3, aka One Project.
  // More details here: https://github.com/wistia/adr/pull/126
  const isV3Enabled = data?.currentAccount?.flagsmithV3Enabled;

  if (isBoolean(isV3Enabled) && isV3Enabled) {
    return (
      <FlagsmithProvider
        flagsmith={flagsmith}
        options={{
          // https://docs.flagsmith.com/clients/javascript#initialisation-options
          cacheFlags: true,
          api: '/',
          cacheOptions: { ttl: flagCacheTTL, skipAPI: true },
          enableLogs: false, // set true for logs
          environmentID: flagsmithV3EnvironmentId,
          headers: { 'X-CSRF-TOKEN': window._auth_token },
          preventFetch: true,
        }}
      >
        <FlagsmithIdentityLoadingContext.Provider value={loading}>
          <>
            <FlagsmithV3IdentityAndTraitsLoader setLoading={setLoadingValue} />
            {children}
          </>
        </FlagsmithIdentityLoadingContext.Provider>
      </FlagsmithProvider>
    );
  }
  if (isAnonymous) {
    return (
      <FlagsmithProvider
        flagsmith={flagsmith}
        options={{
          // https://docs.flagsmith.com/clients/javascript#initialisation-options
          cacheFlags: true,
          api: '/',
          cacheOptions: { ttl: flagCacheTTL, skipAPI: true },
          enableLogs: false, // set true for logs
          environmentID: flagsmithAnonymousEnvironmentId,
          headers: { 'X-CSRF-TOKEN': window._auth_token },
          preventFetch: true,
        }}
      >
        <FlagsmithIdentityLoadingContext.Provider value={loading}>
          <>
            <FlagsmithAnonymousIdentityAndTraitsLoader setLoading={setLoadingValue} />
            {children}
          </>
        </FlagsmithIdentityLoadingContext.Provider>
      </FlagsmithProvider>
    );
  }
  return (
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={{
        // https://docs.flagsmith.com/clients/javascript#initialisation-options
        cacheFlags: true,
        cacheOptions: { ttl: flagCacheTTL, skipAPI: true },
        enableLogs: false, // set true for logs
        environmentID: flagsmithEnvironmentId,
        preventFetch: true, // this flag only prevents initial fetch. After we identify the user things will get fetched
      }}
    >
      <FlagsmithIdentityLoadingContext.Provider value={loading}>
        <>
          <FlagsmithIdentityAndTraitsLoader
            setLoading={setLoadingValue}
            skip={skipIdentityLoading}
          />
          {children}
        </>
      </FlagsmithIdentityLoadingContext.Provider>
    </FlagsmithProvider>
  );
};
