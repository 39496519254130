import { useFlagsmith } from 'flagsmith/react';
import { useEffect } from 'react';
import { isNonEmptyString, isRecord } from '~/utilities/type-guards';
import { useFlagsmithV3IdentityAndTraitsLoaderQuery } from './__gql/FlagsmithV3IdentityAndTraitsLoader.gql.gen';

export const FlagsmithV3IdentityAndTraitsLoader = ({
  setLoading,
}: {
  setLoading: (loading: boolean) => void;
}): null => {
  const flagsmithInstance = useFlagsmith();
  const { data } = useFlagsmithV3IdentityAndTraitsLoaderQuery();

  const flagsmithId = data?.currentAccount?.flagsmithId;
  const flagsmithIdentityData = data?.currentAccount?.flagsmithIdentityData;

  useEffect(() => {
    const updateFlagsmithIdentity = async () => {
      if (isNonEmptyString(flagsmithId) && isRecord(flagsmithIdentityData)) {
        const isValidIdentityData = (
          identityData: Record<string, unknown>,
        ): identityData is Record<string, boolean | number | string | null> => {
          return Object.values(identityData).every(
            (value) =>
              typeof value === 'string' ||
              typeof value === 'number' ||
              typeof value === 'boolean' ||
              value === null,
          );
        };

        if (isValidIdentityData(flagsmithIdentityData)) {
          await flagsmithInstance.identify(flagsmithId, flagsmithIdentityData);
        }
      }
      setLoading(false);
    };

    void updateFlagsmithIdentity();
  }, [flagsmithId, flagsmithIdentityData, flagsmithInstance, setLoading]);

  return null;
};
