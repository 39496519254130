import { useEffect, useState } from 'react';
import {
  OperationVariables,
  useQuery,
  useLazyQuery,
  useMutation,
  DocumentNode,
  ApolloError,
  QueryResult,
  LazyQueryResultTuple,
  LazyQueryExecFunction,
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { DefinitionNode } from 'graphql';
import { usePreviousValue } from '@wistia/vhs';
import isEqual from 'lodash/isEqual';
import { isNil, isNotNil, Nilable } from '~/utilities/type-guards';
import { reportError } from '~/utilities/errorReporting';
import { useSearchParams } from '~/hooks/useSearchParams';
import { useBottlerContext } from './BottlerApolloContext';

const LONG_LOAD_TIMEOUT = 200;

interface Options<TVariables> {
  persistDataBetweenQueries?: boolean;
  errorPolicy?: 'all' | 'ignore' | 'none';
  skip?: boolean;
  variables?: TVariables;
  onCompleted?: (data: unknown) => void;
  onError?: (error: unknown) => void;
  timeout?: number;
  client?: unknown;
  pollInterval?: number;
  context?: Record<string, unknown>;
}

interface FetchOptions<TVariables> extends Options<TVariables> {
  fetchPolicy?: WatchQueryFetchPolicy;
}

/**
 * @typedef {Object} HookReturn
 * @property {boolean}  isLoading
 * @property {Object=}  data
 * @property {Object=}  error
 * @property {Function} fetchMore
 * @property {() => void=} performQuery

 * Process the return value of the query hook.
 * @param {Array|Object} hookReturn - The return value of the hook.
 * @returns {HookReturn} - An object containing the processed data.
 */
const processReturn = <TData, TVariables extends OperationVariables>(
  hookReturn: LazyQueryResultTuple<TData, TVariables> | QueryResult<TData, TVariables>,
) => {
  if (hookReturn instanceof Array) {
    const [performQuery, { loading: isLoading, error, data, fetchMore }] = hookReturn;
    return { performQuery, isLoading, error, data, fetchMore };
  }

  const { loading: isLoading, error, data, fetchMore } = hookReturn;
  return { isLoading, error, data, fetchMore };
};

const useEnhancedQuery = <TVariables extends OperationVariables, TData>(
  query: DocumentNode,
  hook: (
    ...args: unknown[]
  ) => LazyQueryResultTuple<TData, TVariables> | QueryResult<TData, TVariables>,
  options: Options<TVariables>,
) => {
  const [data, setData] = useState<TData | undefined>();
  const [error, setError] = useState<ApolloError | undefined>();
  const [dataAwareIsLoading, setDataAwareIsLoading] = useState(false);
  const [isLongLoad, setIsLongLoad] = useState(false);
  const previousOptions = usePreviousValue(options);
  const [searchParams] = useSearchParams();
  const xRequestId = searchParams.get('xRequestId');
  const providedOptions = { ...options };

  if (isNotNil(xRequestId)) {
    providedOptions.context = providedOptions.context ?? {};
    providedOptions.context.headers = {
      'x-request-id': xRequestId,
    };
  }

  const {
    data: clearableData,
    isLoading,
    performQuery,
    fetchMore,
  } = processReturn<TData, TVariables>(
    hook(query, {
      ...providedOptions,
      onCompleted: (result: TData) => {
        setData(result);
        setDataAwareIsLoading(false);
        setIsLongLoad(false);
        if (options.onCompleted) {
          options.onCompleted(result);
        }
      },
      onError: (result: ApolloError) => {
        setData(undefined);
        setError(result);
        setDataAwareIsLoading(false);
        setIsLongLoad(false);
        if (options.onError) {
          options.onError(result);
        }

        const [queryName] = query.definitions.map(
          (definitionNode: DefinitionNode) =>
            'name' in definitionNode && definitionNode.name?.value,
        );
        reportError(result, {
          queryName,
          variables: options.variables,
          pillar: 'analyze',
          product: 'analytics',
        });
      },
    }),
  );

  useEffect(() => {
    if (!previousOptions) {
      return;
    }
    if (!isEqual(options.variables, previousOptions.variables)) {
      setError(undefined);
    }
  }, [options, previousOptions]);

  useEffect(() => {
    let timer: Nilable<ReturnType<typeof setTimeout>> = null;
    const timeout = options.timeout ?? LONG_LOAD_TIMEOUT;
    if (isLoading) {
      setDataAwareIsLoading(true);
      timer = setTimeout(() => {
        if (dataAwareIsLoading) {
          setIsLongLoad(true);
        }
      }, timeout);
    }

    return () => {
      if (isNil(timer)) {
        return;
      }
      clearTimeout(timer);
    };
  }, [isLoading, dataAwareIsLoading, options.timeout]);

  return {
    data: options.persistDataBetweenQueries ? data : clearableData,
    performQuery,
    isLoading: dataAwareIsLoading,
    // correct for race condition where isLongLoad is true but clearableData was returned
    isLongLoad: isLongLoad && isNil(clearableData),
    error,
    clearData: () => setData(undefined),
    fetchMore,
  };
};

/**
 * A custom hook for making queries using Bottler.
 *
 * @param {DocumentNode | string} query - The GraphQL query string.
 * @param {Object} providedOptions - Custom options for the query.
 */
export const useBottlerQuery = <
  TData = unknown,
  TVariables extends OperationVariables = Record<string, unknown>,
>(
  query: DocumentNode,
  providedOptions: FetchOptions<TVariables> = {},
): { data?: TData; isLoading: boolean; isLongLoad: boolean; error?: ApolloError } => {
  const client = useBottlerContext();
  const defaultOptions = { client, persistDataBetweenQueries: false };
  const options = { ...defaultOptions, ...providedOptions };

  return useEnhancedQuery<TVariables, TData>(query, useQuery, options);
};

/**
 * A custom hook for making lazy queries using Bottler.
 *
 * @param {DocumentNode | string} query - The GraphQL query string.
 * @param {Object} providedOptions - Custom options for the query.
 */
export const useLazyBottlerQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode,
  providedOptions: Options<TVariables> = {},
): {
  data?: TData;
  isLoading: boolean;
  isLongLoad: boolean;
  error?: ApolloError;
  performQuery?: LazyQueryExecFunction<TData, TVariables>;
} => {
  const client = useBottlerContext();
  const defaultOptions = { client, persistDataBetweenQueries: false };
  const options = { ...defaultOptions, ...providedOptions };

  return useEnhancedQuery<TVariables, TData>(query, useLazyQuery, options);
};

export const useBottlerMutation = <TData = unknown, TVariables = Record<string, unknown>>(
  query: DocumentNode,
  options: Options<TVariables> = {},
): ReturnType<typeof useMutation> => {
  const client = useBottlerContext() as ApolloClient<NormalizedCacheObject>;
  const mutationOptions = { ...options, client };

  // eslint-disable-next-line rulesdir/no-use-mutation -- Bottler isn't compatible with graphql-codegen
  return useMutation<TData, TVariables>(query, mutationOptions);
};
